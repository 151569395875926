import React from 'react';
import { useNavigate } from 'react-router-dom';

export interface Post {
  id: number;
  business: {
    name: string;
    username: string;
    avatar: string;
  };
  content: string;
  hashtags: string[];
  likes: number;
  comments: number;
}

interface FeedPostProps {
  post: Post;
  showHeader?: boolean;
}

const FeedPost: React.FC<FeedPostProps> = ({ post, showHeader = true }) => {
  const navigate = useNavigate();

  const handleBusinessClick = (username: string) => {
    navigate(`/business/${username.replace('@', '')}`);
  };

  return (
    <div className="bg-white mb-4 rounded-2xl shadow-sm overflow-hidden">
      {/* Business Header - Optional */}
      {showHeader && (
        <div 
          className="flex items-center p-4 cursor-pointer hover:bg-gray-50"
          onClick={() => handleBusinessClick(post.business.username)}
        >
          <div className="w-12 h-12 rounded-full bg-gray-200 overflow-hidden shadow-sm border border-gray-200">
            <img 
              src={post.business.avatar} 
              alt="" 
              className="w-full h-full object-cover"
            />
          </div>
          <div className="ml-3">
            <div className="font-semibold text-blue-600">{post.business.name}</div>
            <div className="text-sm text-gray-500">{post.business.username}</div>
          </div>
        </div>
      )}

      {/* Post Content */}
      <div className="px-4 pb-3 pt-4">
        <div className="text-[20px] leading-[1.3] mb-2">
          {post.content}
        </div>

        {/* Hashtags */}
        <div className="mb-4">
          {post.hashtags.map((tag) => (
            <span key={tag} className="text-gray-500 mr-1">#{tag}</span>
          ))}
        </div>

        {/* Engagement Metrics */}
        <div className="flex items-center text-gray-500">
          <div className="flex items-center mr-8">
            <span className="mr-2">❤️</span>
            <span className="text-[15px]">{post.likes}</span>
          </div>
          <div className="flex items-center mr-8">
            <span className="mr-2">💬</span>
            <span className="text-[15px]">{post.comments}</span>
          </div>
          <div className="flex items-center">
            <span className="mr-2">↗️</span>
            <span className="text-[15px]">share</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FeedPost; 