import React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Session } from '@supabase/supabase-js';
import { Profile } from '../services/api';
import PageContainer from './PageContainer';
import BottomNav from './BottomNavigation';
import FeedPost, { Post } from './FeedPost';

// Mock data structure that would come from API
interface BusinessData {
  name: string;
  username: string;
  avatar: string;
  description: string;
  updates: Post[];
}

const MOCK_BUSINESSES: Record<string, BusinessData> = {
  "goindigo": {
    name: "Indigo Airlines",
    username: "@goindigo",
    avatar: "https://seeklogo.com/images/I/indigo-logo-EDBB4B3C09-seeklogo.com.png",
    description: "IndiGo is India's largest Airline providing cheap and reliable flights across the world.",
    updates: [
      {
        id: 1,
        business: {
          name: "Indigo Airlines",
          username: "@goindigo",
          avatar: "https://seeklogo.com/images/I/indigo-logo-EDBB4B3C09-seeklogo.com.png"
        },
        content: "Exciting news! We've just added 5 new routes connecting Mumbai to South East Asia. Book now for a special discount!",
        hashtags: ["Thailand", "Indonesia"],
        likes: 245,
        comments: 5
      },
      {
        id: 2,
        business: {
          name: "Indigo Airlines",
          username: "@goindigo",
          avatar: "https://seeklogo.com/images/I/indigo-logo-EDBB4B3C09-seeklogo.com.png"
        },
        content: "Fly our new A350 aircraft between Mumbai and Delhi for more legroom.",
        hashtags: ["Legroom", "AirTravel", "Mumbai"],
        likes: 188,
        comments: 12
      }
    ]
  },
  "cc-resto": {
    name: "Copper Chimney",
    username: "@cc-resto",
    avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3ymX8eaUXjZ5U4aLLZdMetgPt9iD_V0jvzw&s",
    description: "Authentic Indian cuisine since 1972. Experience the rich flavors of India with our signature dishes and traditional recipes.",
    updates: [
      {
        id: 1,
        business: {
          name: "Copper Chimney",
          username: "@cc-resto",
          avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3ymX8eaUXjZ5U4aLLZdMetgPt9iD_V0jvzw&s"
        },
        content: "Come try out all new Diwali menu as an unlimited thali on Sunday Oct 30th",
        hashtags: ["Brunch", "IndianFood", "Diwali"],
        likes: 245,
        comments: 5
      },
      {
        id: 2,
        business: {
          name: "Copper Chimney",
          username: "@cc-resto",
          avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3ymX8eaUXjZ5U4aLLZdMetgPt9iD_V0jvzw&s"
        },
        content: "Introducing our new Chef's Special Menu! Experience the finest North Indian delicacies crafted by our master chefs.",
        hashtags: ["ChefSpecial", "IndianCuisine", "Mumbai"],
        likes: 167,
        comments: 8
      }
    ]
  }
};

interface NewBusinessProfileProps {
  session: Session;
  profile: Profile | null;
}

const NewBusinessProfile: React.FC<NewBusinessProfileProps> = ({ session, profile }) => {
  const { username } = useParams<{ username: string }>();
  const navigate = useNavigate();
  
  // Get business data based on URL parameter
  const businessData = username ? MOCK_BUSINESSES[username] : null;

  // Handle case when business is not found
  if (!businessData) {
    return (
      <PageContainer>
        <header className="sticky top-0 bg-white border-b z-10">
          <div className="flex items-center px-4 py-2">
            <button 
              onClick={() => navigate(-1)}
              className="p-2 -ml-2 text-gray-600"
            >
              ← back
            </button>
          </div>
        </header>
        <div className="p-4">
          <h1 className="text-xl font-semibold">Business not found</h1>
          <p className="text-gray-600 mt-2">The business you're looking for doesn't exist or has been removed.</p>
        </div>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      {/* Header */}
      <header className="sticky top-0 bg-white border-b z-10">
        <div className="flex items-center px-4 py-2">
          <button 
            onClick={() => navigate(-1)}
            className="p-2 -ml-2 text-gray-600"
          >
            ← back
          </button>
        </div>
      </header>

      {/* Profile Section */}
      <div className="px-4 py-6">
        <div className="flex items-center">
          <div className="w-20 h-20 rounded-full bg-gray-200 overflow-hidden shadow-sm border border-gray-200">
            <img 
              src={businessData.avatar}
              alt={businessData.name}
              className="w-full h-full object-cover"
            />
          </div>
          <div className="ml-4">
            <h1 className="text-2xl font-bold">{businessData.name}</h1>
            <p className="text-gray-500">{businessData.username}</p>
          </div>
        </div>
        <p className="mt-4 text-gray-600">
          {businessData.description}
        </p>
      </div>

      {/* Action Buttons */}
      <div className="px-4 pb-4 flex gap-2">
        <button className="flex-1 bg-black text-white rounded-full py-2.5">
          Message
        </button>
        <button className="flex-1 bg-black text-white rounded-full py-2.5">
          Call
        </button>
        <button className="flex-1 bg-black text-white rounded-full py-2.5">
          Favorite
        </button>
      </div>

      {/* Quick Actions */}
      <div className="px-4 pb-4 flex gap-2">
        <button className="flex-1 bg-gray-100 text-gray-600 rounded-full py-2 text-sm">
          updates
        </button>
        <button className="flex-1 bg-gray-100 text-gray-600 rounded-full py-2 text-sm">
          offers
        </button>
        <button className="flex-1 bg-gray-100 text-gray-600 rounded-full py-2 text-sm">
          get help
        </button>
        <button className="flex-1 bg-gray-100 text-gray-600 rounded-full py-2 text-sm">
          book new
        </button>
      </div>

      {/* Updates Feed */}
      <div className="pb-20 px-4">
        {businessData.updates.map((update) => (
          <FeedPost key={update.id} post={update} showHeader={false} />
        ))}
      </div>

      <BottomNav />
    </PageContainer>
  );
};

export default NewBusinessProfile; 