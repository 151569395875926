import React from 'react';
import { Session } from '@supabase/supabase-js';
import { Profile } from '../services/api';
import AppHeader from './AppHeader';
import BottomNav from './BottomNavigation';
import PageContainer from './PageContainer';
import FeedPost, { Post } from './FeedPost';

interface AppFeedProps {
  session: Session;
  profile: Profile | null;
}

// TODO: Pull these posts from server
const SAMPLE_POSTS: Post[] = [
  {
    id: 1,
    business: {
      name: "Indigo Airlines",
      username: "@goindigo",
      avatar: "https://seeklogo.com/images/I/indigo-logo-EDBB4B3C09-seeklogo.com.png"
    },
    content: "Exciting news! We've just added 5 new routes connecting Mumbai to South East Asia. Book now for a special discount!",
    hashtags: ["Thailand", "Indonesia"],
    likes: 245,
    comments: 5
  },
  {
    id: 2,
    business: {
      name: "Copper Chimney",
      username: "@cc-resto",
      avatar: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS3ymX8eaUXjZ5U4aLLZdMetgPt9iD_V0jvzw&s"
    },
    content: "Come try out all new Diwali menu as an unlimited thali on Sunday Oct 30th",
    hashtags: ["Brunch", "IndianFood", "Diwali"],
    likes: 245,
    comments: 5
  }
];

const AppFeed: React.FC<AppFeedProps> = ({ session, profile }) => {
  return (
    <PageContainer>
      <AppHeader session={session} profile={profile} />
      
      <main className="flex-1 px-4 pb-16 pt-3">
        {SAMPLE_POSTS.map((post) => (
          <FeedPost key={post.id} post={post} />
        ))}
      </main>

      <BottomNav />
    </PageContainer>
  );
};

export default AppFeed;