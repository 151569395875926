import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { supabase } from './lib/supabaseClient';
import LandingPage from './components/LandingPage';
import NewBusinessProfile from './components/NewBusinessProfile';
import AuthComponent from './components/Auth';
import ChatList from './components/ChatList';
import { Session } from '@supabase/supabase-js';
import { getProfileInfo, Profile } from './services/api';
import WorkflowContainer from './components/WorkflowContainer';
import AppFeed from './components/AppFeed';
import ProfilePage from './components/ProfilePage';

// Add a loading component
const LoadingComponent = () => <div>Checking authentication...</div>;

function App() {
  const [session, setSession] = useState<Session | null>(null);
  const [profile, setProfile] = useState<Profile | null>(null);
  const [loading, setLoading] = useState(true);  // Add this line

  useEffect(() => {
    const fetchSession = async () => {
      setLoading(true);  // Set loading to true when starting to fetch
      const { data: { session } } = await supabase.auth.getSession();
      setSession(session);
      if (session) {
        try {
          const profileData = await getProfileInfo(session);
          setProfile(profileData.data);
        } catch (error) {
          console.error('Error fetching profile info:', error);
        }
      }
      setLoading(false);  // Set loading to false when done
    };

    fetchSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (_event, session) => {
      setLoading(true);  // Set loading to true when auth state changes
      setSession(session);
      if (session) {
        try {
          const profileData = await getProfileInfo(session);
          setProfile(profileData.data);
        } catch (error) {
          console.error('Error fetching profile info:', error);
        }
      } else {
        setProfile(null);
      }
      setLoading(false);  // Set loading to false when done
    });

    return () => subscription.unsubscribe();
  }, []);

  const handleSignOut = () => {
    setSession(null);
    setProfile(null);
  };

  // If still loading, show loading component
  if (loading) {
    return <LoadingComponent />;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route 
            path="/" 
            element={session ? <AppFeed session={session} profile={profile} /> : <LandingPage />} 
          />
          <Route 
            path="/login" 
            element={!session ? <AuthComponent /> : <Navigate to="/" />} 
          />
          <Route 
            path="/business/:username" 
            element={session ? <NewBusinessProfile session={session} profile={profile} /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/chats" 
            element={session ? <ChatList profile={profile} session={session} /> : <Navigate to="/login" />}
          />
          <Route 
            path="/workflow/4014" 
            element={session ? <WorkflowContainer session={session} /> : <Navigate to="/login" />} 
          />
          <Route 
            path="/profile" 
            element={session ? (
              <ProfilePage 
                session={session} 
                profile={profile} 
                onSignOut={handleSignOut} 
              />
            ) : <Navigate to="/login" />} 
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
