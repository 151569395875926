import React from 'react';
import { Session } from '@supabase/supabase-js';
import { supabase } from '../lib/supabaseClient';
import { Profile } from '../services/api';
import BottomNavigation from './BottomNavigation';
import AppHeader from './AppHeader';
import PageContainer from './PageContainer';

interface ProfilePageProps {
  session: Session | null;
  profile: Profile | null;
  onSignOut: () => void;
}

const ProfilePage: React.FC<ProfilePageProps> = ({ session, profile, onSignOut }) => {

  const handleSignOut = async () => {
    try {
      // Clear local storage first
      localStorage.clear();
      
      // Attempt to sign out from Supabase
      const { error } = await supabase.auth.signOut();
      if (error) {
        console.error('Supabase signOut error:', error);
      }
      
      // Call onSignOut regardless of Supabase error
      onSignOut();
      
      // Optional: Force reload the page to clear any lingering state
      window.location.reload();
    } catch (error) {
      console.error('Error signing out:', error);
      // Still call onSignOut even if there's an error
      onSignOut();
    }
  };

  // TODO: Only allow this if a user is part of a business
  const goToConsole = () => {
    const currentHostname = window.location.hostname;
    let destinationUrl;

    if (currentHostname === 'localhost') {
      // If running locally, redirect to port 3001
      destinationUrl = 'http://localhost:3001';
    } else {
      // In production, add 'console.' to the hostname
      destinationUrl = `https://console.${currentHostname}`;
    }

    window.location.href = destinationUrl;
  };

  if (!session) {
    return <div>Please log in</div>;
  }

  return (
    <PageContainer>
      <AppHeader session={session} profile={profile} />

      <div className="flex-grow p-4 pt-0">
        <div className="bg-white rounded-lg shadow-md p-6 space-y-4 mt-4">
          <div>
            <label className="block text-gray-600 font-semibold mb-2">Name</label>
            <p className="text-gray-900 text-lg">{profile?.first_name || 'Not set'}</p>
          </div>
          
          <div>
            <label className="block text-gray-600 font-semibold mb-2">Email</label>
            <p className="text-gray-900 text-lg">{session.user.email}</p>
          </div>
          
          <button 
            onClick={goToConsole}
            className="w-full bg-gray-100 text-gray-800 py-3 rounded-lg hover:bg-gray-200 transition duration-300 mb-4"
          >
            Go to Console
          </button>

          <button 
            onClick={handleSignOut}
            className="w-full bg-blue-500 text-white py-3 rounded-lg hover:bg-blue-600 transition duration-300"
          >
            Sign Out
          </button>
        </div>
      </div>
      
      <BottomNavigation />
    </PageContainer>
  );
};

export default ProfilePage;